import Api from '../utils/api';

const getFeedEditos = async () => {
  try {
    const res = await Api.hero.get('/edito/lasteditos');
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const getMyEditos = async () => {
  try {
    const res = await Api.hero.get('/webapp/myedito');
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const readEditoById = async (editoId) => {
  try {
    const res = await Api.hero.get(`/readedito/${editoId}`);
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const getBeEditos = async (page) => {
  try {
    const res = await Api.hero.get(`/edito/beeditos?page=${page + 1}`);
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const getOrgaEditos = async (page) => {
  try {
    const res = await Api.hero.get(`/edito/webapp/orgaEdito?page=${page}`);
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const createEdito = async (title, description, isPublic) => {
  try {
    const res = await Api.hero.post('/users/webapp/edito', {
      title,
      description,
      isPublic,
    });
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const updateEdito = async (editoId, title, description, articles, isPublic, image) => {
  try {
    const res = await Api.hero.patch(`/v2/edito/${editoId}`, {
      title,
      description,
      isPublic,
      articles,
      image,
    });
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const deleteEdito = async (editoId) => {
  try {
    const res = await Api.hero.delete(`/webapp/edito/${editoId}`);
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const addArticleToEdito = async (editoId, articles, image = null) => {
  try {
    const res = await Api.hero.post(`/v2/edito/${editoId}/articles`, {
      articles,
      image,
    });
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const addArticleToDefault = async (article) => {
  try {
    const res = await Api.hero.post('/v2/article/bookmark', {
      article,
    });
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

const removeArticleFromEdito = async (editoId, articles) => {
  try {
    const res = await Api.hero.delete(`/v2/edito/${editoId}/articles`, {
      articles,
    });
    return res;
  } catch (e) {
    return { error: { message: e.message } };
  }
};

export default {
  getFeedEditos,
  getOrgaEditos,
  getBeEditos,
  getMyEditos,
  readEditoById,
  createEdito,
  updateEdito,
  deleteEdito,
  addArticleToEdito,
  removeArticleFromEdito,
  addArticleToDefault,
};

import React from 'react';
import {
  StyleSheet,
} from 'react-native';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';

import { useUserContextState } from '../../../contexts/UserContext';
import { useContentContextState } from '../../../contexts/ContentContext';
import COLORS from '../../../utils/colors';
import Card from '../../Common/Card/Card';
import BmmModal from '../../Common/Modal/BmmModal';
import { editoDefault, editoType } from '../../../validation/edito';
import EditoForm from '../../Forms/EditoForm/EditoForm';
import editoApi from '../../../api/editoApi';
import analytics from '../../../utils/analytics';

const styles = StyleSheet.create({
  modalContent: {
    width: '35%',
    height: '80%',
    backgroundColor: COLORS.background,
  },
});

function EditoModal({
  style,
  edito,
  modalVisible,
  onClose,
}) {
  const navigation = useNavigation();
  const contentContext = useContentContextState();
  const userContext = useUserContextState();
  const submitForm = async (title, description, isPublic) => {
    if (edito) {
      analytics.track('user clicked on save edito modifications button');
      const articlesUrl = edito.articles.map((article) => {
        if (article.url.includes('/api/v2/Documents/')) {
          return article;
        }
        return article.url;
      });
      analytics.track('user did edit edito', {
        edito: title, articlesCount: articlesUrl.length, type: null, isPublic,
      });
      await contentContext.updateEdito(edito.id, title, description, articlesUrl, isPublic, edito.image);
    } else {
      analytics.track('user did create edito', {
        edito: title, articlesCount: 0, type: null, isPublic,
      });
      await contentContext.createEdito(title, description, isPublic);
      userContext.executeAction('edito_create');
    }
  };

  const deleteEdito = async () => {
    analytics.track('user confirmed edito deletion', { edito: edito.title });
    await contentContext.deleteEdito(edito.id);
    navigation.navigate('Revues', {});
  };

  return (
    <BmmModal modalVisible={modalVisible} style={{ ...style }}>
      <Card isClosable style={styles.modalContent} onClose={onClose}>
        <EditoForm
          edito={edito}
          onDelete={deleteEdito}
          onSubmit={
            async (title, description, isPublic, editoId) => {
              await submitForm(title, description, isPublic, editoId); onClose();
            }
        }
        />
      </Card>
    </BmmModal>
  );
}

EditoModal.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  modalVisible: PropTypes.bool,
  edito: editoType,
  onClose: PropTypes.func,
};

EditoModal.defaultProps = {
  style: {},
  edito: editoDefault,
  modalVisible: false,
  onClose: () => {},
};

export default EditoModal;
